
import { defineComponent } from 'vue';
import { useToast } from 'vue-toastification';

interface WCEvent extends Event {
	detail: {
		enabled: boolean;
	};
}

export default defineComponent({
	name: 'Camera',
	setup() {
		const toast = useToast();
		return { toast };
	},
	data() {
		return {
			hangup: false,
			togglePip: false,
			roomId: '',
		};
	},
	mounted() {
		(this.$refs.camera as HTMLElement).addEventListener('telepresence-started', () => {
			this.toast.success('Telepresence started');
			console.log('Telepresence Started!');
		});
		(this.$refs.camera as HTMLElement).addEventListener('telepresence-stopped', () => {
			this.toast.success('Telepresence stopped');
			console.log('Telepresence Stopped!');
		});
		(this.$refs.camera as HTMLElement).addEventListener('pip-status', (e) => {
			console.log('PIP Status:', (e as WCEvent).detail.enabled);
			this.togglePip = (e as WCEvent).detail.enabled;
		});
	},
	beforeRouteLeave(to, from, next) {
		this.hangup = true;
		next();
	},

});
