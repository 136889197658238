import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f70e1a6c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["hang-up", "room-id", "toggle-pip"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.roomId) = $event)),
      placeholder: "room id"
    }, null, 512), [
      [
        _vModelText,
        _ctx.roomId,
        void 0,
        { lazy: true }
      ]
    ]),
    _createElementVNode("button", {
      class: "command-button",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.togglePip = !_ctx.togglePip))
    }, "Toggle PIP"),
    _createElementVNode("wc-camera", {
      ref: "camera",
      class: "camera",
      "hang-up": _ctx.hangup,
      "room-id": _ctx.roomId,
      "toggle-pip": _ctx.togglePip
    }, null, 8, _hoisted_1)
  ], 64))
}